import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { NavLink } from "react-router-dom";
import aboutMeImg from "../images/aboutme.jpeg";
import Button from "./Button";

/**
 * Represents the About Me section.
 * Displays information about the user.
 *
 * @component
 * @param {string} name - The name of the user.
 */

const AboutMe = ({ name }) => {
  // Using react-intersection-observer to determine if the component is in view
  const [ref, inView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  // Variants for staggered animations
  const staggerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  // Variants for paragraph animations
  const paragraphVariants = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  };

  return (
    <section className="about">
      <div className="aboutContainer container">
        <div className="row">
          <motion.div
            className="personalImage col-12 col-lg-6"
            ref={ref}
            initial={{ x: "-10vw", opacity: 0, scale: 0.5 }}
            animate={inView ? { x: 0, opacity: 1, scale: 1 } : { x: "-10vw", opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
            whileHover={{ scale: 1.05 }}
          >
            {/* Display the personal image */}
            <motion.img src={aboutMeImg} alt={name} />
          </motion.div>
          <div className="personalInfo col-12 col-lg-6">
            <motion.div className="contentContainer" variants={staggerVariants}>
              {/* Anzeige von Begrüßung und Berufsbezeichnung mit Animation */}
              <motion.h4 variants={paragraphVariants}>Hallo aus Frankfurt! 👋🏻</motion.h4>
              <motion.h5 variants={paragraphVariants}>Ich bin freiberuflicher Softwareentwickler und offen für Flutter-Jobs</motion.h5>

              {/* Anzeige der Inhaltsbeschreibung mit Animation */}
              <motion.div
                className="contentDescription"
                variants={staggerVariants}
                initial="initial"
                animate={inView ? "animate" : "initial"}
              >
                {/* Absätze mit Animation */}
                <motion.p variants={paragraphVariants}>
                  Ich bin wirklich begeistert von meiner Arbeit mit Flutter und Dart. Ich liebe es, freiberuflich zu arbeiten und <span style={{ color: "var(--hl-color)" }}>direkt mit Teams persönlich zusammenzuarbeiten</span>, besonders im Raum Frankfurt.
                </motion.p>
                <br />

                <motion.p variants={paragraphVariants}>
                  Ich arbeite seit <span style={{ color: "var(--hl-color)" }}>über vier Jahren</span> mit Flutter und in dieser Zeit hatte ich das Vergnügen, vier Apps (Versicherung, Finanzen, Energie, White-Label) sowohl in den Apple- als auch in den Google-App-Stores zu veröffentlichen, und ich habe bereits meine eigene App in den AppStores veröffentlicht.
                </motion.p>
                <br />
                <motion.p variants={paragraphVariants}>
                  Neben meiner freiberuflichen Tätigkeit konzentriere ich mich auf <a href="https://accessboard.io" target="_blank" rel="noreferrer">
                    <span style={{ textDecoration: "underline",color: "var(--hl-color)" }}>Accessboard</span></a>, ein spezielles Content-Management-System nur für Flutter-Mobile-Apps. Es ist Teil von Google Startups, hat ein Stipendium von Hessen-Idea erhalten und ist im H_Venture-Startup-Programm.
                </motion.p>
                <br />
                <motion.p variants={paragraphVariants}>

                  Ich bin auch verantwortlich für <a href="https://www.meetup.com/flutter-frankfurt/" target="_blank" rel="noreferrer">
                    <span style={{ textDecoration: "underline", color: "var(--hl-color)" }}>die Organisation von Flutter-Meetups</span>
                  </a> in Frankfurt. Diese Veranstaltungen bringen Flutter-Entwickler zusammen, um Wissen zu teilen, Vorträge zu halten und über die neuesten Trends und Techniken zu diskutieren. Es ist eine großartige Möglichkeit, eine lebendige und unterstützende Community in der Region zu fördern.
                </motion.p>
              </motion.div>

              {/* Button zum Anzeigen des Portfolios */}
              <NavLink to="/portfolio">
                <Button name="Portfolio ansehen" />
              </NavLink>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
