import projects from "../_data/projects.json";
import ProjectCard from "./ProjectCard";

/**
 * Represents a list of project cards.
 *
 * This component maps over the projects data and generates
 * a ProjectCard component for each project.
 *
 * @component
 */

const ProjectList = () =>
  projects.map((project) => (
    <ProjectCard key={project.id} type={project.type} status={project.status} title={project.title} image={project.image} color={project.bgcolor} />
  ));

export default ProjectList;
